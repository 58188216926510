import { getAuthSBLInstance, IosBridgeContext, IosBridgeHandlerName, PubSubBus } from '@avenue-8/platform-shared-util-frontend'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useAuthContext } from '../../shared/contexts/auth.context'
import { Intercom } from './intercom'
import { MobileNavbar } from './mobile-navbar'
import { NavMenuItem } from './navbar'

const HomeIcon: NavMenuItem = {
  href: '/account/home',
  icon: 'home',
}

export type HistoryMobileNavbarProps = {
  homeLogoHref: string;
  menus: NavMenuItem[];
  history?: History;
  isHomeVisible?: boolean
};

export const HistoryMobileNavbar = ({
  homeLogoHref,
  menus,
  isHomeVisible = true,
}: HistoryMobileNavbarProps): JSX.Element => {
  const history = useHistory()
  const location = useLocation()
  const { user, intercomCredentials } = useAuthContext()
  const { postMessage } = useContext(IosBridgeContext)
  const [active, setActive] = useState<NavMenuItem | null>(null)
  const [hidden, setHidden] = useState<boolean>(false)

  const { appId: intercomAppId, userHash } = intercomCredentials ?? {}

  const bottomMenus = isHomeVisible && menus.length
? [
    ...menus.slice(0, Math.floor(menus.length / 2)),
    HomeIcon,
    ...menus.slice(Math.ceil(menus.length / 2)),
  ]
: menus

  const authSBL = getAuthSBLInstance()
  const [credentials, setCredentials] = useState<{ token?: string; agentId?: string }>(null)

  useEffect(() => {
    const handler = async (credentials: { token?: string; agentId?: string; }) => {
      setCredentials({
        token: credentials?.token,
        agentId: credentials?.agentId,
      })
    }
    authSBL.addOnAuthChangeListener(handler)
    authSBL.getCredentials().then(handler)
    return () => authSBL.removeOnAuthChangeListener(handler)
  }, [authSBL])

  const handlePushRoute = (url: string, useRouter = false) => {
    if (url === HomeIcon.href) {
      if (postMessage) {
        postMessage(IosBridgeHandlerName.backHome)
      } else {
        // TODO: add logic to check if should trigger a "You sure you want to leave?" block modal
        window.location.href = homeLogoHref
      }
    } else {
      const mobileParams = new URLSearchParams({ token: credentials?.token, agentId: credentials?.agentId, mbd: 'true' }).toString()
      if (useRouter) {
        history.push({
          pathname: url,
          search: postMessage ? mobileParams : '',
        })
        return
      }

      if (postMessage) {
        window.location.href = `${url}?${mobileParams}`
      } else {
        window.location.href = url
      }
    }
  }

  useEffect(() => {
    const topic = 'hidden-navbar-mfe'
    const handler = (newState = false) => {
      setHidden(newState)
    }
    PubSubBus.subscribe(topic, handler)
    return () => {
      PubSubBus.unsubscribe(topic, handler)
    }
  }, [])

  useEffect(() => {
    const currentlyActive: NavMenuItem | null =
      menus.find(({ href }) => {
        return location.pathname.indexOf(href) === 0
      }) ?? null

    setActive(currentlyActive)
  }, [location.pathname, menus])

  const intercomEl: JSX.Element = useMemo(() => intercomCredentials && (
      <Intercom
        key={'mobile-intercom'}
        appId={intercomAppId}
        user={{ ...user, userHash }}
        alignment='right'
        verticalPadding={60}
      />
    ), [intercomAppId, intercomCredentials, user, userHash])

  if (hidden) {
    return <div data-testid='mobile-hidden'>{intercomEl}</div>
  }

  return <>
    <MobileNavbar active={active} menus={bottomMenus} onPushRoute={handlePushRoute} />
    {intercomEl}
  </>
}
