import {
  IosBridgeProvider,
  PubSubBus,
} from "@avenue-8/platform-shared-util-frontend";
import { useScreenSize } from "@avenue-8/platform-style-util-frontend";
import { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./shared/contexts/auth.context";
import { ServicesProvider } from "./shared/contexts/services.context";
import { HistoryNavbar } from "./navbar/components/history-navbar";
import { HistoryMobileNavbar } from "./navbar/components/history-mobile-navbar";
import useNavbarMenuFlagsmith from "./navbar/hooks/useNavbarMenuFlagsmith";
import useNavbarLogoFlagsmith from "./navbar/hooks/useNavbarLogoFlagsmith";

export default function NavBarModule(): JSX.Element {
  const { isTablet, isMobile } = useScreenSize();
  const menuList = useNavbarMenuFlagsmith();
  const logoUrl = useNavbarLogoFlagsmith();

  useEffect(() => {
    const questionTopic = "is-navbar-ready";
    const handler = () => {
      PubSubBus.publish("navbar-ready", true);
    };
    handler();
    PubSubBus.subscribe(questionTopic, handler);
    return () => {
      PubSubBus.unsubscribe(questionTopic, handler);
    };
  }, []);

  return (
    <Router>
      <IosBridgeProvider>
        <AuthProvider>
          <ServicesProvider>
            {isTablet || isMobile ? (
              <HistoryMobileNavbar
                homeLogoHref="/account/home"
                menus={menuList}
                isHomeVisible={false}
              />
            ) : (
              <HistoryNavbar hrefLogo={logoUrl} menus={menuList} />
            )}
          </ServicesProvider>
        </AuthProvider>
      </IosBridgeProvider>
    </Router>
  );
}
