import { theme } from '@avenue-8/platform-style-util-frontend'
import { Fragment, useEffect } from 'react'
import { IntercomUserDto } from '../../shared/domain/dto/intercom-user.dto'

const getIntercomScript = (appId: string) =>
  `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');` +
  `ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){` +
  `i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';` +
  `s.async=true;s.src='https://widget.intercom.io/widget/${appId}';var x=d.getElementsByTagName('script')[0];` +
  `x.parentNode.insertBefore(s,x);};l()}})()`

const generateIntercomScriptEl = (appId: string) => {
  const el = document.createElement('script')
  el.innerHTML = getIntercomScript(appId)
  return el
}

const w = window as Window & {
  Intercom?: ((action: 'boot', options: Record<string, any>) => void) &
    ((action: 'onHide' | 'onShow', handler: () => void) => void) &
    ((action: 'onUnreadCountChange', handler: (count: number) => void) => void);
}

export type IntercomProps = {
  appId: string;
  user: IntercomUserDto;
  backgroundColor?: string;
  actionColor?: string;
  alignment?: string;
  horizontalPadding?: number;
  verticalPadding?: number;
  onHide?: () => void;
  onShow?: () => void;
  onUnreadCountChange?: (unreadCount: number) => void;
};

export const Intercom = (props: IntercomProps): JSX.Element => {
  const { appId } = props
  const intercomScriptEl = generateIntercomScriptEl(appId)
  useEffect(() => {
    if (!w.Intercom || typeof w.Intercom !== 'function') {
      document.head.appendChild(intercomScriptEl)
    }
  }, [intercomScriptEl])
  useEffect(() => {
    const {
      user,
      backgroundColor = theme.palette.secondary.contrastText,
      actionColor = theme.palette.primary.contrastText,
      alignment = 'left',
      horizontalPadding = 20,
      verticalPadding = 20,
      onHide = null,
      onShow = null,
      onUnreadCountChange = null,
    } = props

    if (w.Intercom && typeof w.Intercom === 'function') {
      const { Intercom: intercom } = w
      intercom('boot', {
        app_id: appId,
        name: user.firstName,
        email: user.email,
        user_id: user.id,
        user_hash: user.userHash,
        created_at: user.createdAt,
        alignment,
        background_color: backgroundColor,
        action_color: actionColor,
        horizontal_padding: horizontalPadding,
        vertical_padding: verticalPadding,
      })
      onHide && intercom('onHide', onHide)
      onShow && intercom('onShow', onShow)
      onUnreadCountChange &&
        intercom('onUnreadCountChange', onUnreadCountChange)
    }
  }, [appId, props])
  return <Fragment />
}
