import { getAuthSBLInstance } from '@avenue-8/platform-shared-util-frontend'
import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { IntercomCredentialsDto } from '../domain/dto/intercom-credentials.dto'
import { UserProfileDto } from '../domain/dto/user-profile.dto'
import { useServicesContext } from './services.context'

export interface IAuthContext {
  jwt?: string;
  user?: UserProfileDto;
  intercomCredentials?: IntercomCredentialsDto;
}

const AuthContext = createContext<IAuthContext>({})

export const useAuthContext = (): IAuthContext => useContext(AuthContext)

// eslint-disable-next-line @typescript-eslint/ban-types
export const AuthProvider = ({ children }: PropsWithChildren<{}>): JSX.Element => {
  const { auth: authService } = useServicesContext()
  const [agentId, setAgentId] = useState<number | null>(null)
  const [jwt, setJwt] = useState<string | null>(null)
  const [user, setUser] = useState<UserProfileDto | null>(null)
  const [intercomCredentials, setIntercomCredentials] = useState<IntercomCredentialsDto | null>(null)
  const authSBL = getAuthSBLInstance()
  useEffect(() => {
    const handler = async (credentials: { token?: string; agentId?: string; }) => {
      setAgentId(Number.isNaN(+credentials?.agentId) ? null : +credentials.agentId)
      setJwt(credentials?.token ?? null)
    }
    authSBL.addOnAuthChangeListener(handler)
    authSBL.getCredentials().then(handler)
    return () => {
      authSBL.removeOnAuthChangeListener(handler)
    }
  }, [authSBL])
  useEffect(() => {
    if (!(agentId && jwt)) {
      setUser(null)
      return
    }
    authService.getIntercomCredentials()
      .then(credentials => {
        setIntercomCredentials(credentials)
      })
    authService.getProfile()
      .then(profile => {
        setUser(profile)
      })
  }, [authService, agentId, jwt])
  return <AuthContext.Provider value={{ jwt, user, intercomCredentials }}>{children}</AuthContext.Provider>
}
