import { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router'
import { useAuthContext } from '../../shared/contexts/auth.context'
import { NavMenuItem, Navbar } from './navbar'
import { Intercom } from './intercom'

export type HistoryNavbarProps = {
  hrefLogo: string
  menus: NavMenuItem[]
  history?: History
}

export const HistoryNavbar = ({
  hrefLogo,
  menus,
}: HistoryNavbarProps): JSX.Element => {
  const location = useLocation()
  const { user, intercomCredentials } = useAuthContext()
  const history = useHistory()
  const { appId: intercomAppId, userHash } = intercomCredentials ?? {}
  const [active, setActive] = useState<NavMenuItem | null>(null)

  const handlePushRoute = (url: string, useRouter = false) => {
    // TODO: add logic to check if should trigger a "You sure you want to leave?" block modal
    if (useRouter) {
      history.push(url)
    } else {
      window.location.href = url
    }
  }

  useEffect(() => {
    const currentlyActive: NavMenuItem | null =
      menus.find(({ href }: NavMenuItem) => {
        return location.pathname.indexOf(href) === 0
      }) ?? null

    setActive(currentlyActive)
  }, [location.pathname, menus])

  return (
    <Navbar
      hrefLogo={hrefLogo}
      menus={menus}
      onPushRoute={handlePushRoute}
      active={active}
    >
      {intercomCredentials && (
        <Intercom
          key={'desktop-intercom'}
          appId={intercomAppId}
          user={{ ...user, userHash }}
        />
      )}
    </Navbar>
  )
}
