import { PlatformIconNames } from '@avenue-8/platform-style-util-frontend'
import { css } from '@emotion/css'
import { PropsWithChildren } from 'react'
import { NavbarButton } from './navbar-button'
import { NavbarLogo } from './navbar-logo'

const navbarRoot = css`
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    min-width: 32px;
    max-width: 105px;
    background-color: #fafaff;
    height: 100vh;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    z-index: 99;
  `
  const topItems = css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `
  const bottomItems = css`
    display: flex;
    flex-direction: column-reverse;
    flex-shrink: 1;
  `

export type NavMenuItem = {
  title?: string
  href: string
  icon: PlatformIconNames
  useRouter?: boolean
}

export type NavbarProps = PropsWithChildren<{
  hrefLogo: string
  menus: NavMenuItem[]
  onPushRoute: (url: string, useRouter?: boolean) => void
  active?: NavMenuItem
}>

export const Navbar = ({
  hrefLogo,
  active,
  menus,
  onPushRoute,
  children,
}: NavbarProps): JSX.Element => {
  return (
    <nav className={navbarRoot}>
      <div className={topItems}>
        <NavbarLogo href={hrefLogo}>AVENUE 8</NavbarLogo>
        {menus.map((menu, idx) => (
          <NavbarButton
            data-testid={`navbar-button-${idx}`}
            key={idx}
            title={menu.title}
            icon={menu.icon}
            onClick={() => onPushRoute(menu.href, menu?.useRouter)}
            active={menu === active}
          />
        ))}
      </div>
      <div className={bottomItems}>{children}</div>
    </nav>
  )
}
