export interface IConfigService {
  get(key: string): string | null;
  getOrFail(key: string): string;
}

export function ConfigServiceFactory(dict: Record<string, string> = process.env): IConfigService {
  return {
    get(key) {
      return dict[key] ?? null
    },
    getOrFail(key) {
      if (!(key in dict)) {
        throw new Error(`Missing env var ${key}`)
      }
      return dict[key]
    },
  }
}

export const ConfigService = ConfigServiceFactory()
