import { CSSProperties, MouseEventHandler, PropsWithChildren } from 'react'

const anchorStyle = {
    marginTop: '3em',
    marginBottom: '4em',
    flexShrink: 1,
    textDecoration: 'none',
    color: 'var(--primary, #b1b2c2)',
  }
  const containerStyle: CSSProperties = {
    transform: 'rotate(-90deg)',
    padding: 0,
  }
  const textStyle: CSSProperties = {
    whiteSpace: 'nowrap',
    fontSize: '1.2em',
    fontFamily: 'Roboto, Arial, sans-serif',
    fontWeight: 500,
  }

export type NavBarLogoProps = PropsWithChildren<{
  href?: string
  onClick?: MouseEventHandler<HTMLAnchorElement>
}>

export const NavbarLogo = ({
  href = '#',
  children,
  onClick,
}: NavBarLogoProps): JSX.Element => {
  const handleClick = (event) => {
    if (onClick) {
      event.preventDefault()
      onClick(event)
    }
  }

  return (
    <a href={href} onClick={handleClick} style={anchorStyle} data-testid='navbar-logo'>
      <div style={containerStyle}>
        <span style={textStyle}>{children}</span>
      </div>
    </a>
  )
}
