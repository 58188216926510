import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { NavMenuItem } from "../components/navbar";
import { useFeatureFlags } from "@avenue-8/platform-shared-util-frontend";

export default function useNavbarMenuFlagsmith() {
  const [menuList, setMenuList] = useState<NavMenuItem[]>([]);
  const { pathname } = useLocation();
  const { getFeatureData } = useFeatureFlags();

  const getNavbarMenuList = useCallback(
    async (flag: string) => {
      await getFeatureData(flag)
        .then((data) => {
          if (!data) return;
          if (!Array.isArray(data)) {
            throw new Error("invalid homescreen navbar tabs feature flag");
          }
          setMenuList(data as NavMenuItem[]);
        })
        .catch((error) => error);
    },
    [getFeatureData]
  );

  useEffect(() => {
    if (
      pathname.startsWith("/account/marketing") ||
      pathname.startsWith("/closing")
    ) {
      getNavbarMenuList("pam2-navbar-menu-options");
    } else if (pathname.startsWith("/account/home")) {
      getNavbarMenuList("platform-homescreen-navbar-menu-options");
    }
  }, [getNavbarMenuList, pathname]);

  return menuList;
}
