import { FeatureFlagsProvider } from "@avenue-8/platform-shared-util-frontend";
import { theme } from "@avenue-8/platform-style-util-frontend";
import { ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import NavBarModule from "./modules/nav-bar-module";
import { ErrorBoundaryProvider } from "./modules/shared/components/error-boundary-provider";

export default function Root(props: {
  name: string;
  singleSpa: any;
}): JSX.Element {
  return (
    <FeatureFlagsProvider>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <ErrorBoundaryProvider>
            <NavBarModule />
          </ErrorBoundaryProvider>
        </ThemeProvider>
      </BrowserRouter>
    </FeatureFlagsProvider>
  );
}
