import { createContext, PropsWithChildren, useContext } from 'react'
import { AuthService, IAuthService } from '../services/auth.service'
import {
  ConfigService,
  IConfigService,
} from '../services/config.service'

export interface IServicesContext {
  config: IConfigService;
  auth: IAuthService;
}

const servicesContextValue = Object.freeze({
  config: ConfigService,
  auth: AuthService,
})

const ServicesContext = createContext<IServicesContext>(servicesContextValue)

export const useServicesContext = (): IServicesContext => useContext(ServicesContext)
// eslint-disable-next-line @typescript-eslint/ban-types
export const ServicesProvider = ({ children }: PropsWithChildren<{}>): JSX.Element => (
  <ServicesContext.Provider value={servicesContextValue}>{children}</ServicesContext.Provider>
)
