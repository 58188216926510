import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import Root from './root.component'

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your micro frontend here.
    console.error('[navbar]', err, info)
    return <p>Oops... Navbar crashed</p>
  },
})

export const { bootstrap, mount, unmount } = lifecycles
