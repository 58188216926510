import { getAuthSBLInstance, AUTH_SERVICE_URL } from '@avenue-8/platform-shared-util-frontend'
import { IntercomCredentialsDto } from '../domain/dto/intercom-credentials.dto'
import { UserProfileDto } from '../domain/dto/user-profile.dto'

export interface IAuthService {
  _dispose(): void;
  getProfile(): Promise<UserProfileDto>;
  getIntercomCredentials(): Promise<IntercomCredentialsDto>;
}

function getRequestParams(
  agentId: string,
  token: string,
): { params: URLSearchParams; headers: Headers } {
  const params = new URLSearchParams({ agentId })
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${token}`)
  return { params, headers }
}

export function AuthServiceFactory(baseURL: string): IAuthService {
  const authSBL = getAuthSBLInstance()
  let jwt: string | null = null
  let agentId: string | null = null

  const handler = async (
    credentials: { token?: string; agentId?: string } | null,
  ) => {
    const { token = null, agentId: id = null } = credentials ?? {}
    jwt = token ?? null
    agentId = id ?? null
  }

  authSBL.addOnAuthChangeListener(handler)
  authSBL.getCredentials().then(handler)

  return {
    _dispose() {
      authSBL.removeOnAuthChangeListener(handler)
    },
    async getIntercomCredentials(): Promise<IntercomCredentialsDto> {
      const { params, headers } = getRequestParams(agentId, jwt)
      return fetch(`${baseURL}/intercom/credentials?${params}`, {
        headers,
        credentials: 'include',
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              '[Navbar/Auth Service] Failed to fetch intercom credentials',
            )
          }
          return response.json()
        })
        .then((data: IntercomCredentialsDto) => {
          // eslint-disable-next-line no-console
          console.log('[Navbar/Auth Service]', { data })
          return data
        })
        .catch(() => null)
    },
    async getProfile(): Promise<UserProfileDto> {
      const { params, headers } = getRequestParams(agentId, jwt)
      return fetch(`${baseURL}/agent?${params}`, {
        headers,
        credentials: 'include',
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              '[Navbar/Auth Service] Failed to fetch agent profile',
            )
          }
          return response.json()
        })
        .then((data: UserProfileDto) => {
          // eslint-disable-next-line no-console
          console.log('[Navbar/Auth Service] Got Intercom Credentials', {
            data,
          })
          return data
        })
        .catch(() => null)
    },
  }
}

export const AuthService = AuthServiceFactory(AUTH_SERVICE_URL)
