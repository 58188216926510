import { useCallback, useEffect, useState } from 'react'
import { useFeatureFlags } from '@avenue-8/platform-shared-util-frontend'

export default function useNavbarLogoFlagsmith() {
  const [logoHref, setLogoHref] = useState<string>('')
  const { getFeatureData } = useFeatureFlags()

  const getNavbarMenuList = useCallback(async () => {
    await getFeatureData('platform-avenue8-logo').then((data) => {
      if (!data) return
      setLogoHref((data as any).href)
    })
  }, [getFeatureData])

  useEffect(() => {
    getNavbarMenuList()
  }, [getNavbarMenuList])

  return logoHref
}
