import { PlatformIcon } from '@avenue-8/platform-style-util-frontend'
import styled from '@emotion/styled'
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
  Typography,
} from '@mui/material'
import { ReactNode, SyntheticEvent } from 'react'
import { NavMenuItem } from './navbar'

const BottomNavigationContainer = styled(Paper)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100vw;
`

const PaddedBottomNavigation = styled(BottomNavigation)`
  padding-left: 15%;
  padding-right: 15%;
  padding-bottom: 0.4em;
`

export type MobileNavbarProps = {
  active: NavMenuItem
  menus: NavMenuItem[]
  onPushRoute: (href: string, useRouter?: boolean) => void
}

export const MobileNavbar = ({
  active,
  menus,
  onPushRoute,
}: MobileNavbarProps): JSX.Element => {
  return menus.length
? (
    <BottomNavigationContainer elevation={3} data-testid='menus-container'>
      <PaddedBottomNavigation value={active?.href} showLabels>
        {menus.map(
          ({ href, icon, title, useRouter = false }, idx): ReactNode => (
            <BottomNavigationAction
              data-testid={`mobile-navbar-item-${idx}`}
              onClick={() => onPushRoute(href, useRouter)}
              key={idx}
              icon={
                <Box pt={active?.href === href ? '0px' : '10px'}>
                  <PlatformIcon name={icon} />
                </Box>
              }
              value={href}
              label={
                !!title && (
                  <Typography
                    sx={{
                      textTransform: 'uppercase',
                      mt: '5px',
                      fontSize: '10px',
                      letterSpacing: '2px',
                      fontWeight: 400,
                      color: active?.href === href ? 'black' : '#b1b2c2',
                    }}
                  >
                    {title}
                  </Typography>
                )
              }
            />
          ),
        )}
      </PaddedBottomNavigation>
    </BottomNavigationContainer>
  )
: (
    <></>
  )
}
