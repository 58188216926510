import {
  PlatformIcon,
  PlatformIconNames,
} from '@avenue-8/platform-style-util-frontend'
import { Button, Typography, Box } from '@mui/material'
import { MouseEventHandler } from 'react'

export type NavBarButtonProps<T> = {
  title?: string
  icon: PlatformIconNames
  onClick?: MouseEventHandler<T>
  active?: boolean
}

export const NavbarButton = ({
  title,
  icon,
  onClick,
  active = false,
  ...props
}: NavBarButtonProps<HTMLButtonElement>): JSX.Element => {
  const style = {
    border: 'none',
    width: '100%',
    '&:hover': {
      border: 'none',
    },
    height: '100px',
  }
  const activeStyleChange = {
    borderBottom: 'solid 2.5px rgba(10, 10, 10, 0.99)',
    borderRadius: 2,
    marginBottom: '-1px',
  }
  const childrenContainerStyle = {
    padding: 0, 
  }
  const iconStyle = {
    padding: 0,
    margin: 0,
    color: active ? 'black' : '#b1b2c2',
    width: '100%',
    display: 'inline',
    ...(active ? activeStyleChange : {}),
    '&>svg': {
      marginBottom: '-1px',
    },
  }
  return (
    <Button onClick={onClick} variant='outlined' sx={style} {...props}>
      <Box style={childrenContainerStyle}>
        <Box style={iconStyle}>
          <PlatformIcon name={icon} />
        </Box>
        {!!title && (
          <Typography
            sx={{
              textTransform: 'uppercase',
              fontSize: '10px',
              letterSpacing: '2px',
              fontWeight: 400,
              color: active ? 'black' : '#b1b2c2',
              mt: active ? 1 : 0,
            }}
          >
            {title}
          </Typography>
        )}
      </Box>
    </Button>
  )
}
